import React from "react";
import { DatePicker,Form,Input,InputNumber } from 'antd';
import moment from 'moment';
import styles from './ShowProjectDetailForm.module.css';
const { TextArea } = Input;

const ShowProjectDetailForm = ({ values }) => {
    const initialValues = values ? {
        accumulated_consumption_points: values.accumulated_consumption_points,
        project_name: values.project_name,
        company_name: values.company_name,
        estimated_completion_date:   moment(values.estimated_completion_date, 'YYYY/MM/DD'),
        website: values.website,
        recruitment_position: values.recruitment_position,
        salaryRange: {
            min: values.salaryRange_min,
            max: values.salaryRange_max,
        },
        job_description: values.job_description,
        job_requirements: values.job_requirements,
        estimated_number_of_recruits: values.estimated_number_of_recruits,
        estimated_number_of_interviews: values.estimated_number_of_interviews,
        other_requirements: values.other_requirements,
    } : {};
    const inputStyle = {
        backgroundColor: '#F9FAFB', // 灰色背景
        color: '#475467', // 黑色文字
        cursor: 'default', 
    };
    return (
        <div style={{ marginLeft: '30px', height: '70vh', overflowY: 'auto' }}>
            <Form
                key={JSON.stringify(initialValues)}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 14, offset: 3 }}
                layout="horizontal"
                colon={false}
                labelAlign={'left'}
                style={{ maxWidth: 900 }}
                initialValues={initialValues}
            >
                <Form.Item label="Accumulated consumption points" name="accumulated_consumption_points" style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '25px', marginBottom: '25px'}}>
                    <Input addonBefore={<img src={`${process.env.PUBLIC_URL}/moneyIcon.svg`} alt="Money Icon" style={{ width: 14, height: 14 }} />}
                    className={styles.customDisabledInput} style={inputStyle} disabled />
                </Form.Item>

                <Form.Item label="Project Name" name="project_name">
                    <Input placeholder="Project Name" style={inputStyle} disabled />
                </Form.Item>

                <Form.Item label="Company Name" name="company_name">
                    <Input placeholder="Company Name" style={inputStyle} disabled />
                </Form.Item>

                <Form.Item label="Estimated Completion Date" name="estimated_completion_date" style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '25px', marginBottom: '25px'}}>
                    <div>
                        <Input
                            style={{ ...inputStyle, width: '100%' }}
                            value={initialValues.estimated_completion_date ? initialValues.estimated_completion_date.format('YYYY/MM/DD') : ''}
                            readOnly
                            disabled
                        />
                        <DatePicker
                            className={styles.customDatePicker}
                            style={{ display: 'none' }}
                            disabled
                        />
                    </div>
                </Form.Item> 

                <Form.Item label="Website" name="website" style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '25px', marginBottom: '25px'}}>
                    <Input placeholder="Company Website" style={inputStyle} disabled />
                </Form.Item>

                <Form.Item label="Recruitment Position" name="recruitment_position">
                    <Input placeholder="Recruitment Position" style={inputStyle} disabled />
                </Form.Item>

                <Form.Item label="Salary Range">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Item
                            name={['salaryRange', 'min']}
                            noStyle
                        >
                            <InputNumber addonBefore="$" className={styles.customInputNumber} style={{ ...inputStyle, width: '40%' }} placeholder="Minimum Salary" disabled />
                        </Form.Item>
                        <div
                            style={{
                                flexGrow: 1,
                                height: '1px',
                                backgroundColor: '#e0e0e0',
                                margin: '0 40px',
                            }}
                        />
                        <Form.Item
                            name={['salaryRange', 'max']}
                            noStyle
                        >
                            <InputNumber addonBefore="$" className={styles.customInputNumber} style={{ width: '40%' }} placeholder="Maximum Salary" disabled/>
                        </Form.Item>
                    </div>
                </Form.Item>

                <Form.Item label="Job Description" name="job_description">
                    <TextArea rows={5} placeholder="Job Description" style={inputStyle} readOnly />
                </Form.Item>

                <Form.Item label="Job Requirements" name="job_requirements" style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '25px', marginBottom: '25px'}}>
                    <TextArea rows={5} placeholder="Job Requirements" style={inputStyle} readOnly />
                </Form.Item>

                <Form.Item label="Estimated number of recruits" name="estimated_number_of_recruits">
                    <InputNumber placeholder="0" className={styles.customInputNumber} style={{ width: '100%' }} disabled />
                </Form.Item>

                <Form.Item label="Estimated number of interviews" name="estimated_number_of_interviews" style={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '25px', marginBottom: '25px'}}>
                    <InputNumber placeholder="0" className={styles.customInputNumber} style={{ width: '100%' }} disabled />
                </Form.Item>

                <Form.Item label="Other Requirements" name="other_requirements">
                    <TextArea rows={5} placeholder="Other specific requirements are displayed here" style={inputStyle} readOnly />
                </Form.Item>
            </Form>
        </div>
    );
};
  
export default ShowProjectDetailForm;