import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Pagination, Row, Col, Button, Modal } from 'antd';
import { HeartOutlined, FileSearchOutlined, FormOutlined } from '@ant-design/icons';

const pageSize = 8;

const CandidateCardList = ({ candidate }) => {
  const [current, setCurrent] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resumeUrl, setResumeUrl] = useState(null);

  const navigate = useNavigate();

  // 格式化候选人数据
  const candidates = candidate.map((item, index) => ({
    id: index + 1,
    name: `${item.first_name} ${item.last_name}`,
    experience: item.experience || "",
    skills: item.skills || [],
    status: item.status,
    resume: item.resume_id,
    usr_id: item.usr_id,
    sponser: item.sponser === "true"
  }));

  const currentCandidates = candidates.slice((current - 1) * pageSize, current * pageSize);

  const handlePageChange = (page) => {
    setCurrent(page);
  };

  const showResumeModal = (url) => {
    setResumeUrl(url);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setResumeUrl(null);
  };

//   const handleCardClick = (id, name) => {
//     navigate(`/candidatedetail?id=${id}&name=${name}`);
//   };

  return (
    <div style={{ padding: '15px', marginTop: '-10px', marginLeft: '15px' }}>
      <Row gutter={[16, 16]} justify="start">
        {currentCandidates.map(candidate => (
          <Col span={6} key={candidate.id}>
            <Card
              title={<div style={{ textAlign: 'center' }}>{candidate.name}</div>}
              style={{
                width: '100%',
                height: '100%',
                textAlign: 'center',
              }}
            //   onClick={() => handleCardClick(candidate.usr_id,candidate.name)}
            >
              <p style={{ textAlign: 'center' }}>
                <b>Need Sponser:</b> {candidate.sponser? 'Yes':'No'}
              </p>
              <p style={{ textAlign: 'center' }}>
                <b>Experience:</b> {candidate.experience}
              </p>
              <p style={{ textAlign: 'center' }}>
                <b>Status:</b> {candidate.status}
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '16px',
                  marginTop: '16px',
                }}
              >
                <Button type="default" shape="circle" icon={<HeartOutlined />} />
                <Button
                  type="default"
                  shape="circle"
                  icon={<FileSearchOutlined />}
                  onClick={(e) => {
                    e.stopPropagation();
                    showResumeModal(candidate.resume);
                  }}
                />
                <Button type="default" shape="circle" icon={<FormOutlined />} />
              </div>
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
        <Pagination
          current={current}
          pageSize={pageSize}
          total={candidates.length}
          onChange={handlePageChange}
        />
      </div>

      <Modal
        title="Resume Viewer"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>
        ]}
        width={800}
      >
        {resumeUrl && (
          <iframe
            src={`https://docs.google.com/gview?url=${resumeUrl}&embedded=true`}
            style={{ width: '100%', height: '600px', border: 'none' }}
            title="Resume Viewer"
          />
        )}
      </Modal>
    </div>
  );
};

export default CandidateCardList;
