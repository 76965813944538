import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styles from './RestPassword.module.css';
import { CloseOutlined } from '@ant-design/icons';
import { signin,clearAuthError } from '../../redux/actions/authActions';
import axios from 'axios';
import config from "../../config/config";

const RestPassword = ({ isOpen, onClose }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [registrationCode, setRegistrationCode] = useState('');
    const [ifSendVerificationCode, setIfSendVerificationCode] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const SignUperror = useSelector((state) => state.auth.error);

    if (!isOpen) return null;

    const handleSubmit = async(event) => {
        event.preventDefault();
        
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }
        // TODO: call API to verfiy registration code and reset password
        try {
            const re = await axios.post(`${config.BACKEND_ENDPOINT}/auth/restpassword`,{userName: username,password: password,resetToken: registrationCode,type:'client'})
            console.log(re.data);
            if(re.data.success){
                setError('');
                dispatch(clearAuthError());
                dispatch(signin({ userName: username, password: password,type:'client' }));
            }
        }catch (error) {
            if(error.response.data.message === 'Verification Code has expired'){
                setIfSendVerificationCode(false);
                setError('Verification Code has expired, please request a new one');
            }else {
                setError(error.response.data.message);
            }
        }
    };

    const handleRequestVerfication = async(event)=>{
        event.preventDefault();
        // TODO: call API to check if email exists and send verification code
        try {
            const re = await axios.post(`${config.BACKEND_ENDPOINT}/auth/forgetpassword`,{userName: username,type:'client'})
            if(re.data.success){
                setIfSendVerificationCode(true);
                setError('')
            }
        } catch (error) {
            console.log(error);
            setError('Invalid email address');
        }
    }

    const handleClose = () => {
        setError('');
        dispatch(clearAuthError());
        onClose();
    };

    const handleInputChange = (setterFunction, value) => {
        setError('');
        setterFunction(value);
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={handleClose}>
                    <CloseOutlined style={{ fontSize: '20px', color: '#98A2B3' }} />
                </button>
                <div className={styles.titleBox}>
                    <div className={styles.title}>Rest Password</div>
                    <div className={styles.subtitle}>Please Change your password.</div>
                </div>
               {ifSendVerificationCode === false?
                    <form onSubmit={handleRequestVerfication}>
                        <div className={styles.inputField}>
                            <label className={styles.label}>Email Address</label>
                            <input
                                className={styles.input}
                                type="text"
                                value={username}
                                onChange={(e) => handleInputChange(setUsername, e.target.value)}
                                placeholder="Email Address"
                                required
                            />
                        </div>
                        <button className={styles.submitButton} type="submit">Send Verification Code</button>
                        {error && <div className={styles.error}>{error}</div>}
                        {SignUperror && <div className={styles.error}>{SignUperror}</div>}
                    </form>:

                    <form onSubmit={handleSubmit}>
                        <div className={styles.inputField}>
                            <label className={styles.label}>Password</label>
                            <input
                                className={styles.input}
                                type="password"
                                value={password}
                                onChange={(e) => handleInputChange(setPassword, e.target.value)}
                                placeholder="Password"
                                required
                            />
                        </div>
                        <div className={styles.inputField}>
                            <label className={styles.label}>Confirm Password</label>
                            <input
                                className={styles.input}
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => handleInputChange(setConfirmPassword, e.target.value)}
                                placeholder="Confirm Password"
                                required
                            />
                        </div>
                        <div className={styles.inputField}>
                            <label className={styles.label}>Verification Code</label>
                            <input
                                className={styles.input}
                                type="text"
                                value={registrationCode}
                                onChange={(e) => handleInputChange(setRegistrationCode, e.target.value)}
                                placeholder="Verification Code"
                                required
                            />
                        </div>
                        <button className={styles.submitButton} type="submit">Rest Password</button>
                        {error && <div className={styles.error}>{error}</div>}
                        {SignUperror && <div className={styles.error}>{SignUperror}</div>}
                    </form>
               }
                
            </div>
        </div>
    );
};

export default RestPassword;
